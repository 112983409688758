@import "vars";

/* TABLE */

.app-tbody:hover {
  background-color: $gray-200;
  color: $link;
  text-decoration: underline;
  cursor: pointer;
}

/* TOOLTIP */

.label.info-tooltip {
  background-color: inherit;
  border: none !important;
}

.cui .form-group .form-group__text.select:after {
  bottom: var(--cui-form-icon-yoffset);
  transform: rotate(0);
  transition: transform var(--cui-animate-speed)
    var(--cui-animate-timing-function);
  content: "";
  width: 0;
  height: 0;
  display: inline-flex;
  align-items: center;
  opacity: 1;
  border-radius: 2px;
  margin-bottom: 2px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--cui-font-color);
  position: absolute;
  right: var(--cui-spacing-half);
}

/* ACCORDION */

.accordion {
  li {
    &.active {
      .accordion__icon {
        transform: rotate(315deg);
        transition: 0.25s all ease-in-out;
      }
      .accordion__content {
        max-height: 1500em !important;
      }
    }
    &.not-active {
      .accordion__icon {
        transform: rotate(0deg);
        transition: 0.25s all ease-in-out;
      }
    }
  }
  .accordion__content {
    position: relative;
    max-height: 1000em;
  }

  tr.panel {
    &.active {
      .accordion__icon {
        transform: rotate(180deg);
        transition: 0.25s all ease-in-out;
      }
      .accordion__content {
        max-height: 1500em !important;
      }
    }
    &.not-active {
      .accordion__icon {
        transform: rotate(0deg);
        transition: 0.25s all ease-in-out;
      }
    }

    &.accordion__title {
      cursor: pointer;
    }
  }
}

.cui .accordion > li.active .accordion__title .accordion__toggle .icon-add {
  transform: rotate(135deg) !important;
}

li.not-active > div {
  visibility: hidden;
  height: 0;
}

/* MODAL */

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* SCAN STATUS */

.status {
  &.submitted {
    color: $primary;
  }
  &.running {
    color: $warning;
  }
  &.succeeded {
    color: $success;
  }
  &.errored {
    color: $danger;
  }
  &.internal {
    color: $danger;
  }
  &.internal-error {
    color: $danger;
  }
  &.not-connected {
    color: $danger;
  }
  &.terminated {
    color: $dark;
  }
  &.pass {
    color: $success;
  }
  &.fail {
    color: $danger;
  }
  &.not-run {
    color: $dark;
  }
  &.no-coverage {
    color: $tertiary;
  }
}

.cui[data-theme="dark"] {
  .react-datepicker__input-container input {
    background-color: var(--cui-background-inactive);
  }
  .label {
    color: #ffffff;
  }
  .label.label--primary .icon-close {
    color: #ffffff;
  }
  .swagger-ui {
    .auth-container input[type="text"] {
      color: $dark;
    }
    .info {
      .title {
        color: #ffffff;
      }
      .base-url {
        color: #ffffff;
      }
    }
    .opblock-tag {
      color: #ffffff;
    }
    .opblock .opblock-summary-path {
      color: #ffffff;
    }
    .opblock-description-wrapper p {
      color: #ffffff;
    }
    .parameter__name {
      color: #ffffff;
    }
    .parameters-col_description * {
      opacity: 1;
    }
    table thead tr th {
      color: #ffffff;
    }
    table thead tr td {
      color: $gray-200;
    }
    .parameter__type {
      color: $gray-200;
    }
    .response-col_status {
      color: #ffffff;
    }
    .responses-inner h4 {
      color: #ffffff;
    }
    .tab li {
      color: #ffffff;
    }
    table.headers td {
      color: #ffffff;
    }
    label {
      color: #ffffff;
    }
    .models-control {
      color: #ffffff;
    }
    .model-title {
      color: #ffffff;
    }
    .model {
      color: $gray-200;
    }
    table.model tr.description {
      color: $gray-200;
    }
    color: #ffffff;
  }
}

.cui input,
.cui select,
.cui textarea,
.cui button {
  background-color: var(--cui-background-active);
}

.react-datepicker {
  background-color: var(--cui-background-inactive) !important;
  color: var(--cui-form-value-color) !important;
}
.react-datepicker-wrapper {
  background-color: var(--cui-background-color);
}
.react-datepicker__input-container {
  background-color: var(--cui-background-color);
}
.react-datepicker__input-container input {
  background-color: var(--cui-background-color);
  border: var(--cui-border);
  border-radius: var(--cui-border-radius);
  box-sizing: border-box;
  color: var(--cui-form-value-color);
  user-select: none;
  font-weight: var(--cui-form-value-font-weight);
  height: 35px;
  max-height: 35px;
  width: 100%;
  max-width: 100%;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--cui-form-value-color) !important;
  z-index: 1;
  position: relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: var(--cui-form-value-color) !important;
}

.react-datepicker__header {
  background-color: var(--cui-background-active) !important;
  color: var(--cui-form-value-color) !important;
}
